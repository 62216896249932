import axios from 'axios';

import type { ResponseData } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';

import type { CreatePinCodeResult, LookupPinCodeResult, PinCodeKind } from './types';

export function createPinCode({ kind = 37, target }: { kind?: PinCodeKind; target: string }): Promise<CreatePinCodeResult> {
  return new Promise<CreatePinCodeResult>((resolve, reject) => {
    axios
      .post<ResponseData & { data: CreatePinCodeResult }>(`/api/web/v1/pin`, { kind, target })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

const API_HOST = process.env.NEXT_PUBLIC_SERVER_HOST ?? '';

export function lookupPinCode({ pinCode }: { pinCode: string }): Promise<LookupPinCodeResult> {
  // NOTE: Should use absolute link because EU stack is using k8s,
  //       if use relative url then the next-app pod may not found the API host

  return new Promise<LookupPinCodeResult>((resolve, reject) => {
    pureInstance
      .get<ResponseData & { data: LookupPinCodeResult }>(`${API_HOST}/api/web/v1/pin/${pinCode}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
