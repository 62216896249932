import type { GetServerSideProps } from 'next';
import React, { useEffect, useState } from 'react';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import FullPageLoading from '@/components/FullPageLoading';
import { PinCode, PinCodeLayout } from '@/modules/PinCode';
import getLocale from '@/utils/getLocale';

function useIsLoadingScript(): boolean {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) setIsLoading(false);
  }, [isLoading]);

  return isLoading;
}

export default function PinCodeSite() {
  const isLoadingScript = useIsLoadingScript();

  if (isLoadingScript) return <FullPageLoading />;

  return (
    <PinCodeLayout>
      <PinCode />
    </PinCodeLayout>
  );
}

PinCodeSite.skipAuth = true;

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocale(req.headers['accept-language']), ['common', 'pinCode'])),
    },
  };
};
