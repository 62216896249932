import Head from 'next/head';

import { Flex, Stack, chakra, useBreakpointValue } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { useTranslation } from 'next-i18next';

import { Splashtop } from '@/components/Logo';
import { RippleButtonText, RippleDomain, RippleHyperLink } from '@/design';
import theme from '@/design/theme';
import { Footer } from '@/layouts/public/Footer';
import { getRegionData } from '@/utils/region';

const GLOBAL_URL = process.env.NEXT_PUBLIC_HELP123_GLOBAL_URL ?? 'https://help123.app';
const EU_URL = process.env.NEXT_PUBLIC_HELP123_EU_URL ?? 'https://eu.help123.app';

function Wrapper({ children }: { children?: React.ReactNode }): React.JSX.Element {
  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${theme.colors.blue[0]};
          }
        `}
      />
      <Flex pt="64px" direction="column" alignItems="center" h="100%" minH="100vh">
        {children}
      </Flex>
    </>
  );
}

function getEnv(): 'global' | 'eu' {
  const { env } = getRegionData();
  return env;
}

function Navigation({ env = getEnv() }): React.JSX.Element {
  const iconStyles = useBreakpointValue<{ width: number; height: number }>({
    base: { width: 125, height: 24 },
    md: { width: 178, height: 34 },
  });

  return (
    <chakra.nav
      display="flex"
      position="fixed"
      top="0px"
      left="0px"
      bgColor="white"
      h="64px"
      w="100%"
      shadow="nav"
      justifyContent="center"
      alignItems="center"
      px="12px"
      zIndex={100}
    >
      <Flex w={['100%', 750, 970, 1140]} justifyContent="space-between">
        <Flex p="4px">
          <Splashtop {...iconStyles} />
        </Flex>
        <Flex p="4px" alignItems="center">
          <Flex p="4px">
            <RippleDomain color="dark.80" />
          </Flex>
          <Stack spacing="8px" direction="row">
            {env === 'global' && (
              <>
                <RippleButtonText color="dark.80">Global</RippleButtonText>
                <RippleButtonText as="span" color="neutral.60">
                  |
                </RippleButtonText>
                <RippleHyperLink variant="hyperlink02" href={EU_URL}>
                  <RippleButtonText color="inherit">EU</RippleButtonText>
                </RippleHyperLink>
              </>
            )}
            {env === 'eu' && (
              <>
                <RippleHyperLink variant="hyperlink02" href={GLOBAL_URL}>
                  <RippleButtonText color="inherit">Global</RippleButtonText>
                </RippleHyperLink>
                <RippleButtonText as="span" color="neutral.60">
                  |
                </RippleButtonText>
                <RippleButtonText color="dark.80">EU</RippleButtonText>
              </>
            )}
          </Stack>
        </Flex>
      </Flex>
    </chakra.nav>
  );
}

export function PinCodeLayout({ children, env = getEnv() }: { children?: React.ReactNode; env?: 'global' | 'eu' }): React.JSX.Element {
  const { t } = useTranslation();

  const title = `Help123.app - ${t('pinCode:start_support_session')}`;
  const description = `[Splashtop]
${t('pinCode:ticketPhrase1', 'Enter the code from your support technician.')}
${t('pinCode:ticketPhrase2', 'Then click on Start Download.')}`;
  const image = '/next/images/pin_code_metadata_image.png';

  return (
    <Wrapper>
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://help123.splashtop.com/w/pin_code" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://help123.splashtop.com/w/pin_code" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Head>
      <Navigation env={env} />
      <chakra.main flex="1" pt={{ base: '32px', md: '64px' }} mx="15px">
        {children}
      </chakra.main>
      <Footer />
    </Wrapper>
  );
}
