import React, { useRef, useState } from 'react';

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  PinInput,
  PinInputField,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
  useDimensions,
} from '@chakra-ui/react';
import type { FlexProps, IconProps, PinInputFieldProps, StyleProps } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleAlert, RippleButton, RippleButtonText } from '@/design';
import type { RippleButtonProps } from '@/design';
import emptyFunc from '@/utils/emptyFunc';
import getOS from '@/utils/getOS';

function SplashtopLogo(props: IconProps): React.JSX.Element {
  const { width, height } = useBreakpointValue<{ width: number; height: number }>({
    base: { width: 50, height: 48 },
    md: { width: 74, height: 72 },
  }) ?? { width: 50, height: 48 };

  return (
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 74 72" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.4857 31.7613C52.1509 35.8869 53.0618 41.5359 60.704 45.2338C71.0609 50.2954 71.5281 57.6931 67.946 60.0292C64.364 62.4432 61.5606 58.7833 57.5113 51.5413C54.8637 46.869 44.8962 40.1722 36.5641 50.3732C35.7617 51.6214 34.982 52.9376 34.1774 54.2958C32.8736 56.4967 31.5044 58.8081 29.8672 61.1194C25.4285 67.3491 14.0594 72.9558 5.7272 65.0908C-4.86324 55.1234 3.8583 42.9755 4.55913 42.1968C4.64042 42.1065 4.74475 41.9753 4.87674 41.8094C5.88279 40.5445 8.49585 37.2592 14.7602 34.6433C18.9652 32.9302 29.0106 31.9957 37.5764 35.1884C44.6626 36.668 42.015 29.9711 38.1215 26.9341C36.2463 25.4699 33.3289 24.565 30.2861 23.6212C24.1046 21.7038 17.4054 19.6258 17.8751 12.3723C18.3423 4.74091 25.0392 3.02775 28.777 3.10562C30.4123 3.18349 44.0397 4.89665 50.8923 28.8809C51.1521 29.6929 51.3104 30.6742 51.4857 31.7613ZM59.2231 39.6277C59.2231 39.9392 59.3009 40.0949 59.3009 40.0949C59.3788 39.2384 60.8584 35.0333 67.3216 33.3202C71.4488 32.23 73.7849 30.9062 72.7726 28.025C72.0718 26.0782 67.3216 22.3404 62.5715 25.5331C57.7435 28.8037 58.5222 36.2793 59.2231 39.6277Z"
        fill="#0E6DBF" // Splashtop blue
      />
    </Icon>
  );
}

function Divider({ width = 428 }): React.JSX.Element {
  return (
    <Icon width={`${width}px`} height="4px" viewBox={`0 0 ${width} 4`} fill="none" color="blue.20">
      <line x1="2" y1="2" x2={`${width}`} y2="2" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeDasharray="0.1 10" />
    </Icon>
  );
}

function TicketDivider(props: FlexProps): React.JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerDimensions = useDimensions(containerRef, true);

  const cutHoleRadius =
    useBreakpointValue<number>({
      base: 18,
      md: 32,
    }) ?? 18;

  const cutHoleWidth = `${cutHoleRadius}px`;
  const cutHoleHeight = `${cutHoleRadius * 2}px`;

  const leftCutHolePath = [
    'M 0 0', // top-left
    `A ${cutHoleRadius} ${cutHoleRadius} 0 0 1 0 ${cutHoleRadius * 2}`, // left cut hole
    `L ${cutHoleRadius} ${cutHoleRadius * 2}`, // bottom-right
    `L ${cutHoleRadius} 0`,
  ].join(' ');

  const rightCutHolePath = [
    'M 0 0', // top-left
    `L 0 ${cutHoleRadius * 2}`, // bottom-left
    `L ${cutHoleRadius} ${cutHoleRadius * 2}`, // bottom-right
    `A ${cutHoleRadius} ${cutHoleRadius} 0 0 1 ${cutHoleRadius} 0`, // right cut hole
  ].join(' ');

  function calculateDividerWidth(dimensions: NonNullable<ReturnType<typeof useDimensions>>) {
    const paddingX = 12;
    return dimensions.borderBox.width - cutHoleRadius * 2 - paddingX * 2;
  }

  return (
    <Flex ref={containerRef} h={cutHoleHeight} w="100%" {...props}>
      {containerDimensions ? (
        <>
          <Box h={cutHoleHeight} w={cutHoleWidth} bgColor="white" clipPath={`path('${leftCutHolePath}')`} />
          <Flex bgColor="white" justifyContent="center" alignItems="center" flex="1">
            {containerDimensions && <Divider width={calculateDividerWidth(containerDimensions)} />}
          </Flex>
          <Box h={cutHoleHeight} w={cutHoleWidth} bgColor="white" clipPath={`path('${rightCutHolePath}')`} />
        </>
      ) : (
        <Flex bgColor="white" h="100%" w="100%" />
      )}
    </Flex>
  );
}

const CustomPinInputField = forwardRef<PinInputFieldProps, 'input'>((props, ref) => {
  const inputStyles = useBreakpointValue<StyleProps>({
    base: {
      textStyle: 'h4',
      fontSize: '24px',
      lineHeight: '30px',
      borderBottom: '1px solid',
      mr: '4px',
      w: '22px',
      h: '33px',
    },
    md: {
      textStyle: 'h5',
      fontSize: '32px',
      borderBottom: '2px solid',
      mr: '8px',
      w: '36px',
      h: '48px',
    },
  });

  return (
    <PinInputField
      ref={ref}
      borderRadius="0"
      borderBottom="1px solid"
      borderBottomColor="neutral.300"
      _focus={{ outline: 'none' }}
      color="blue.300"
      {...inputStyles}
      {...props}
    />
  );
});

export function PinCodeTicket({
  isError = false,
  onSubmit = emptyFunc,
  isLoading = false,
  os = getOS(),
}: {
  isError: boolean;
  onSubmit: (pinCode: string) => void;
  isLoading: boolean;
  os?: ReturnType<typeof getOS>;
}): React.JSX.Element {
  const { t } = useTranslation();
  const [pinCode, setPinCode] = useState('');

  const isMobile = os === 'iOS' || os === 'Android';

  const downloadButtonText = isMobile ? t('pinCode:launchApp', 'Launch App') : t('pinCode:startDownload', 'Start Download');
  const phrase2Text = isMobile
    ? t('pinCode:ticketPhrase2Mobile', 'Then click on Launch App.')
    : t('pinCode:ticketPhrase2', 'Then click on Start Download.');

  const ticketStyles = useBreakpointValue<StyleProps>({
    base: {
      maxWidth: '448px',
      maxHeight: '477px',
      minWidth: '288px',
    },
    md: {
      width: '526px',
      height: '568px',
    },
  });

  const isDisabled = isLoading || pinCode.length !== 6;

  const buttonSize = useBreakpointValue<RippleButtonProps['size']>({ base: 'md', md: 'lg' });

  function handleSubmit() {
    onSubmit(pinCode);
  }

  return (
    <Box filter="drop-shadow(0px 0px 60px rgba(3, 109, 234, 0.1))">
      <Grid
        {...ticketStyles}
        templateRows={{ base: '130px 36px 311px', md: '168px 64px 336px' }}
        templateColumns="100%"
        overflow="hidden"
        borderRadius="24px"
        justifyContent="center"
      >
        <GridItem bgColor="white">
          <Stack spacing="12px" mt={{ base: '32px', md: '40px' }} alignItems="center">
            <SplashtopLogo />
            <Text as="h1" textStyle={{ base: 'h7', md: 'h5' }}>
              {t('pinCode:start_support_session')}
            </Text>
          </Stack>
        </GridItem>
        <GridItem as={TicketDivider} style={{ direction: 'ltr' }} />
        <GridItem bgColor="white">
          <Flex direction="column" alignItems="center" h="100%">
            <Box as="section" w="100%" px={{ base: '32px', md: '40px' }} mt={{ base: '14px', md: '24px' }}>
              <Text
                textStyle={{ base: 'body2', md: 'body1' }}
                sx={{
                  '&': {
                    textAlign: 'center', // NOTE: avoid textStyle overwrite style
                  },
                }}
              >
                {t('pinCode:ticketPhrase1', 'Enter the code from your support technician.')}
              </Text>
              <Text
                textStyle={{ base: 'body2', md: 'body1' }}
                sx={{
                  '&': {
                    textAlign: 'center', // NOTE: avoid textStyle overwrite style
                  },
                }}
              >
                {phrase2Text}
              </Text>
            </Box>

            <Flex direction="column" alignItems="center" justifyContent="center" flex="1">
              <Flex direction="row" style={{ direction: 'ltr' }}>
                <PinInput variant="unstyled" placeholder="" onChange={setPinCode} autoFocus>
                  <CustomPinInputField />
                  <CustomPinInputField />
                  <CustomPinInputField mr="12px" />
                  <CustomPinInputField />
                  <CustomPinInputField />
                  <CustomPinInputField />
                </PinInput>
              </Flex>

              {isError && (
                <Stack isInline spacing="8px" h="36px" mt="12px" alignItems="baseline" mx={{ base: '36px', md: '64px' }}>
                  <Box>
                    <RippleAlert color="red.100" />
                  </Box>
                  <Text textStyle={{ base: 'body3', md: 'body2' }} sx={{ '&': { color: 'red.100' } }}>
                    {t('pinCode:invalidPinCode', 'The PIN code is invalid. Please contact your support technician.')}
                  </Text>
                </Stack>
              )}
            </Flex>

            <RippleButton
              variant="primary"
              size={buttonSize}
              isDisabled={isDisabled}
              onClick={handleSubmit}
              mb={{ base: '32px', md: '56px' }}
            >
              <RippleButtonText color="inherit">{downloadButtonText}</RippleButtonText>
            </RippleButton>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
}
